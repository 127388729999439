import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cbm',
  standalone: true
})
export class CbmPipe implements PipeTransform {

  transform(value: string, /*...args: unknown[]*/): string {
    return value.replace(/3/g, '<span class="text-xl inline-block leading-4 translate-y-[4px]">³</span>');
  }

}
