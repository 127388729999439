@if (loading) {
<div class="absolute z-10 top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-white/50">
  <mat-spinner diameter="40"></mat-spinner>
</div>
}
<form [formGroup]="createForm" id="create-form" (ngSubmit)="handleSubmit()" class="mb-4 pt-2">
  <mat-form-field appearance="outline" subscriptSizing="dynamic">
    <mat-label>Modpart</mat-label>
    <mat-hint></mat-hint>
    <mat-select formControlName="counterparty">
      @for (option of counterparties; track option) {
      <mat-option [value]="option">{{option.displayName}}</mat-option>
      }
      @if (!counterparties.length) {
      <mat-option disabled><em>Ingen modparter</em></mat-option>
      }
    </mat-select>
  </mat-form-field>
  <!-- </fieldset> -->
  <div>
    <h2 class="text-xl mb-2">Salgsaftaler der afdækkes</h2>
    <app-card [noPadding]="true">

      <table mat-table [dataSource]="order ? order.coveredContracts : contracts" multiTemplateDataRows>
        <ng-container matColumnDef="customer">
          <th mat-header-cell *matHeaderCellDef> Kundenavn </th>
          <td mat-cell *matCellDef="let element">
            {{element.customer.name}}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="account">
          <th mat-header-cell *matHeaderCellDef> Kontonummer </th>
          <td mat-cell *matCellDef="let element">
            {{element.accountNumber}}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="product">
          <th mat-header-cell *matHeaderCellDef> Produkt </th>
          <td mat-cell *matCellDef="let element">
            {{element.product.displayName}}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="volume">
          <th mat-header-cell *matHeaderCellDef> Volumen <span [innerHTML]="'(m3)' | cbm"></span>
          </th>
          <td mat-cell *matCellDef="let element">
            {{element.volume.toLocaleString('da-DK')}}
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <span class="flex justify-between">
              <span>I alt</span>
              <span>{{getVolumeSum()}}</span>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="profile">
          <th mat-header-cell *matHeaderCellDef> Profil </th>
          <td mat-cell *matCellDef="let element">
            {{element.profile }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="period">
          <th mat-header-cell *matHeaderCellDef> Periode </th>
          <td mat-cell *matCellDef="let element">
            {{element.periodTemplate.displayName }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef> Salgspris </th>
          <td mat-cell *matCellDef="let element">
            {{element.price.toLocaleString('da-DK')}}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="managedBy">
          <th mat-header-cell *matHeaderCellDef> Ansvarlig </th>
          <td mat-cell *matCellDef="let element">
            {{element.customerManager }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="signed">
          <th mat-header-cell *matHeaderCellDef> Underskrevet </th>
          <td mat-cell *matCellDef="let element">
            @if (element.signed) {
            <mat-icon aria-hidden="false" aria-label="signed" fontIcon="check" class="tertiary-icon"></mat-icon>
            } @else {
            <mat-icon aria-hidden="false" aria-label="not signed" fontIcon="close" class="primary-icon"></mat-icon>
            }
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
      </table>
    </app-card>
  </div>
</form>
@if (!order) {
<fieldset class="mb-4">
  <legend>E-mail preview</legend>
  <h4 class="font-bold text-lg mb-2 text-secondary-dark">Emne</h4>
  <p class="mb-4 text-secondary-dark">{{getEmailTemplate(profile).subject}}</p>
  <h4 class="font-bold text-lg mb-2 text-secondary-dark">Indhold</h4>
  <app-card>
    <div class="text-secondary-dark" [innerHTML]="getEmailTemplate(profile!).message | safeHtml"></div>
  </app-card>
</fieldset>
}
<app-card class="sticky bottom-0 right-4">
  <div class="flex justify-between w-full">
    @if(order && order.confirmed) {
    <button mat-button>Gå til ordrebekræftelse</button>
    }
    <div class="flex justify-end grow">
      <button mat-button (click)="cancel.emit()">Annullér</button>
      <button mat-button (click)="handleSubmit()">
        @if (order) {
        Gem
        } @else {
        Tilføj og send e-mail
        }
      </button>
    </div>
  </div>
</app-card>