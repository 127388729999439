import { Component, Input } from "@angular/core";
import { CbmPipe } from "../cbm.pipe";

@Component({
  selector: 'app-dashboard-table',
  template: `
    <table class="text-secondary-dark w-full">
      <thead>
        <tr class="border-b">
          @for (item of headers; track item) {
          <th class="px-4 text-right whitespace-nowrap" [innerHTML]="item | cbm"></th>
          }
        </tr>
      </thead>
      <tbody>
        @for (item of data; track item) {
        <tr>
          <td class="px-4 font-bold whitespace-nowrap">{{ item.title }}</td>
          <td class="px-4 text-right">{{ item.count }}</td>
          <td class="px-4 text-right">{{ item.volume }}</td>
        </tr>
        }
      </tbody>
    </table>
  `,
  styles: '',
  standalone: true,
  imports: [CbmPipe],
})

export class DashboardTableComponent {
  @Input() data?: {title: string; count: number; volume: number}[]
  @Input() headers?: string[]
}