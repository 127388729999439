@if (submitting) {
<div class="absolute z-10 top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-white/50">
  <mat-spinner diameter="40"></mat-spinner>
</div>
}

<form class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4" [formGroup]="contractForm" id="contract-form"
  (ngSubmit)="handleSubmit()">
  <fieldset class="border p-4 rounded flex flex-col gap-4">
    <legend>Grundoplysninger</legend>
    <!-- Dato -->
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>Dato</mat-label>
      <input matInput [matDatepicker]="picker" required formControlName="date">
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-hint>DD.MM.ÅÅÅÅ</mat-hint>
      @if (contractForm.controls.date.invalid) {
      <mat-error>
        @if (contractForm.controls.date.hasError('required')) {
        Du skal udfylde en valid dato
        }
      </mat-error>
      }
    </mat-form-field>
    <!-- Kunde -->
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>Kunde</mat-label>
      @if (contractForm.controls.customer.disabled && !submitting) {
      <div class="absolute w-full flex justify-end">
        <mat-spinner diameter="20"></mat-spinner>
      </div>
      }
      <input type="text" matInput formControlName="customer" [matAutocomplete]="auto" required
        (input)="handleCustomerInput($event)" #customerSelect>
      <mat-hint>Fremsøg og vælg en kunde på listen</mat-hint>
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        @if (gettingCustomers) {
        <div class="bg-black/15 absolute left-0 top-0 right-0 bottom-0 flex justify-center items-center">
          <mat-spinner diameter="30"></mat-spinner>
        </div>
        }
        @for (option of customerOptions; track option.id) {
        <mat-option [value]="option">{{option.name}} <span
            class="text-secondary-lighter ml-2">{{option.customerNumber}}</span></mat-option>
        }
      </mat-autocomplete>
      @if (contractForm.controls.customer.invalid) {
      <mat-error>
        @if (contractForm.controls.customer.hasError('required')) {
        Du skal vælge en kunde
        }
      </mat-error>
      }
    </mat-form-field>
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>Kontonummer</mat-label>
      <mat-hint>Vælg en kunde for at vælge et kontonummer</mat-hint>
      <mat-select formControlName="accountNumber">
        <mat-option [value]="null">
          <span class="text-secondary-lighter italic">Intet kontonummer</span>
        </mat-option>
        @for (option of accountNumberOptions; track $index) {
        <mat-option [value]="option">{{option}}</mat-option>
        }
        @if (!accountNumberOptions.length) {
        <mat-option disabled><em>Ingen kontonumre</em></mat-option>
        }
      </mat-select>
    </mat-form-field>
  </fieldset>
  <fieldset class="flex flex-col gap-4">
    <legend>Produkt</legend>
    <!-- Produkt -->
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>Produkt</mat-label>
      <mat-hint>Fremsøg og vælg et produkt på listen</mat-hint>
      <input type="text" matInput formControlName="product" required [matAutocomplete]="productGroup">
      <mat-autocomplete #productGroup="matAutocomplete" [displayWith]="productDisplayFn">
        @for (group of productGroupOptions | async; track group.name) {
        <mat-optgroup [label]="group.name">
          @for (product of group.products; track product.id) {
          <mat-option [value]="product">
            @if (product.prefix) {
            <span class="text-secondary-light">{{product.prefix}}</span>
            }
            {{product.displayName}}
          </mat-option>
          }
        </mat-optgroup>
        }
      </mat-autocomplete>
    </mat-form-field>
    <!-- Salgspris -->
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>Salgspris</mat-label>
      <input matInput required formControlName="price" type="number" />
      @if (contractForm.controls.price.invalid) {
      <mat-error>
        @if (contractForm.controls.price.hasError('required')) {
        Du skal udfylde salgsprisen
        }
        @if (contractForm.controls.price.hasError('pattern')) {
        Feltet kan kun indeholde et positivt tal
        }
      </mat-error>
      }
    </mat-form-field>
    <!-- Solgt volumen -->
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>Solgt volumen</mat-label>
      <input matInput formControlName="volume" required type="number" />
      @if (contractForm.controls.volume.invalid) {
      <mat-error>
        @if (contractForm.controls.volume.hasError('required')) {
        Du skal indtaste volumen
        }
        @if (contractForm.controls.volume.hasError('pattern')) {
        Feltet kan kun indeholde et positivt tal
        }
      </mat-error>
      }
    </mat-form-field>
  </fieldset>
  <fieldset class="flex flex-col gap-4">
    <legend>Periode</legend>
    <!-- Profil -->
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>Profil</mat-label>
      <mat-select formControlName="profile" name="profile">
        @for (profile of profiles; track profile) {
        <mat-option [value]="profile">{{profile}}</mat-option>
        }
      </mat-select>
      @if (contractForm.controls.profile.invalid) {
      <mat-error>
        @if (contractForm.controls.profile.hasError('required')) {
        Du skal vælge en profil
        }
      </mat-error>
      }
    </mat-form-field>
    <!-- periodeskabelon -->
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>Periodeskabelon</mat-label>
      <mat-select formControlName="periodTemplate" name="periodTemplate">
        @for (period of periods; track period) {
        <mat-option [value]="period">{{period.displayName}}</mat-option>
        }
      </mat-select>
      @if (contractForm.controls.periodTemplate.invalid) {
      <mat-error>
        @if (contractForm.controls.periodTemplate.hasError('required')) {
        Du skal vælge en periodeskabelon
        }
      </mat-error>
      }
    </mat-form-field>
    <!-- Periode fra/til -->
    <div formGroupName="period" class="w-full flex flex-col gap-4">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-full">
        <mat-label>Periode fra</mat-label>
        <input matInput formControlName="from" [matDatepicker]="fromPicker" required readonly>
        <!-- <mat-hint>DD.MM.ÅÅÅÅ</mat-hint> -->
        <mat-datepicker-toggle matIconSuffix [for]="fromPicker"></mat-datepicker-toggle>
        <mat-datepicker #fromPicker startView="multi-year"
          (monthSelected)="setFromDate($event, fromPicker)"></mat-datepicker>
        @if (contractForm.get(['period', 'from'])!.invalid) {
        <mat-error>
          @if (contractForm.get(['period', 'from'])!.hasError('required')) {
          Du skal udfylde en valid dato
          }
        </mat-error>
        }
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-full">
        <mat-label>Periode til</mat-label>
        <input matInput formControlName="to" [matDatepicker]="toPicker" required readonly>
        <!-- <mat-hint>DD.MM.ÅÅÅÅ</mat-hint> -->
        <mat-datepicker-toggle matIconSuffix [for]="toPicker"></mat-datepicker-toggle>
        <mat-datepicker #toPicker startView="multi-year" (monthSelected)="setToDate($event, toPicker)"></mat-datepicker>
        @if (contractForm.get(['period', 'to'])!.invalid) {
        <mat-error>
          @if (contractForm.get(['period', 'to'])!.hasError('required')) {
          Du skal udfylde en valid dato
          }
        </mat-error>
        }
      </mat-form-field>
    </div>
    <!-- Kontraktmodtagelse -->
  </fieldset>
  <fieldset class="col-span-3">
    <legend>Fordeling på periode</legend>
    @if (contractForm.controls.periodTemplate.value && contractForm.controls.profile.value) {
    <h3 class="text-secondary-dark text-lg font-bold mb-2">2024</h3>
    <div class="grid grid-flow-col gap-2">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" floatLabel="always">
        <mat-label>JAN.</mat-label>
        <input matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" floatLabel="always">
        <mat-label>FEB.</mat-label>
        <input matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" floatLabel="always">
        <mat-label>MAR.</mat-label>
        <input matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" floatLabel="always">
        <mat-label>APR.</mat-label>
        <input matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" floatLabel="always">
        <mat-label>MAJ</mat-label>
        <input matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" floatLabel="always">
        <mat-label>JUN.</mat-label>
        <input matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" floatLabel="always">
        <mat-label>JUL.</mat-label>
        <input matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" floatLabel="always">
        <mat-label>AUG.</mat-label>
        <input matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" floatLabel="always">
        <mat-label>SEP.</mat-label>
        <input matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" floatLabel="always">
        <mat-label>OKT.</mat-label>
        <input matInput disabled />
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" floatLabel="always">
        <mat-label>NOV.</mat-label>
        <input matInput value="100" />
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" floatLabel="always">
        <mat-label>DEC.</mat-label>
        <input matInput value="100" />
      </mat-form-field>
    </div>
    } @else {
    <p class="italic text-secondary-lighter text-center w-full">Vælg profil og periode for at vise
      fordeling</p>
    }
  </fieldset>
  <div class="col-span-3">
    <mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
      <mat-label>Bemærkninger</mat-label>
      <textarea matInput formControlName="notes" placeholder="" rows="4"></textarea>
    </mat-form-field>
  </div>
  @if (editMode) {
  <mat-checkbox formControlName="signed">Kontrakt underskrevet af kunde?</mat-checkbox>
  }
</form>


<app-card class="sticky bottom-0 right-4">
  <div class="flex justify-between w-full">
    @if(data && data.covered) {
    <button mat-button routerLink="/procurement/agreements" [queryParams]="{order: '12345678'}">Gå til
      prissikringsordre</button>
    }
    <div class="flex justify-end grow">
      <button mat-button (click)="cancel.emit()">Annullér</button>
      <button mat-button form="contract-form">
        @if (editMode) {
        Gem
        } @else {
        Tilføj
        }
      </button>
    </div>
  </div>
</app-card>