import { AsyncPipe } from "@angular/common"
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms"
import { MatAutocompleteModule } from "@angular/material/autocomplete"
import { MatButtonModule } from "@angular/material/button"
import { MatCheckboxModule } from "@angular/material/checkbox"
import { MatDatepicker, MatDatepickerModule } from "@angular/material/datepicker"
import { MatFormFieldModule } from "@angular/material/form-field"
//import { MatIcon } from "@angular/material/icon"
import { MatInputModule } from "@angular/material/input"
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner"
import { MatSelectModule } from "@angular/material/select"
//import { ProductsAutocompleteComponent } from "../../../components/products-autocomplete/products-autocomplete.component"
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core"
import { provideMomentDateAdapter } from "@angular/material-moment-adapter"
import { MAT_DATE_LOCALE } from "@angular/material/core"
import { CustomersService } from "../../../services/customers.service"
import { ProfilesService } from "../../../services/profiles.service"
import { PeriodTemplatesService } from "../../../services/period-templates.service"
import { Contract, Customer, PeriodTemplate, Product } from "../../../types"
import moment, { Moment } from 'moment';
import { Group, GroupOption } from "./oil.component"
import { map, Observable, startWith } from "rxjs"
import { CardComponent } from "../../../components/card.component";
import { RouterLink } from "@angular/router"
import { ContractsService } from "../../../services/contracts.service"

@Component({
  selector: 'app-add-contract',
  templateUrl: 'add-contract.component.html',
  standalone: true,
  imports: [
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    AsyncPipe,
    //MatIcon,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    //ProductsAutocompleteComponent,
    CardComponent,
    RouterLink
],
  providers: [provideMomentDateAdapter(), {provide: MAT_DATE_LOCALE, useValue: 'da-DK'}]
})
export class AddContractComponent implements OnInit, OnChanges {
  @Input() show!: boolean
  @Input() data: Contract | null = null
  @Output() addContract = new EventEmitter()
  @Output() cancel = new EventEmitter()
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      if (this.data) {
        this.editMode = true
        this.submitting = true
        this.contractForm.controls.date.setValue(new Date(this.data.date))
        this.contractForm.controls.customer.setValue(this.data.customer)
        this.contractForm.controls.accountNumber.setValue(this.data.accountNumber)
        for (const group of this.productGroups) {
          const product = group.products.find((e) => e.id === this.data?.product.id)
          if (product) {
            this.contractForm.controls.product.setValue(product)
          }
        }
        this.contractForm.controls.volume.setValue(this.data.volume)
        this.contractForm.controls.price.setValue(this.data.price)
        this.contractForm.controls.profile.setValue(this.data.profile)
        this.contractForm.controls.periodTemplate.setValue(this.periods.find((e) => e.displayName === this.data?.periodTemplate.displayName) as PeriodTemplate)
        this.contractForm.controls.period.controls.from.setValue(moment(new Date(this.data.period.from)))
        this.contractForm.controls.period.controls.to.setValue(moment(new Date(this.data.period.to)))
        this.contractForm.controls.notes.setValue(this.data.notes as string)
        this.contractForm.controls.signed.setValue(this.data.signed)
        this.submitting = false
      }
    } else {
      this.editMode = false
      this.contractForm.reset()
      this.contractForm.clearValidators()
    }
    
  }

  editMode = false

  constructor(
    private customersService: CustomersService,
    private profilesService: ProfilesService,
    private periodTemplatesService: PeriodTemplatesService,
    private contractsService: ContractsService
  ) {}

  /** Form */
  contractForm = new FormGroup({
    //contractNumber: new FormControl('', [Validators.required, Validators.pattern('^([0-9]{4}-{1}[0-9]{6})$')]),
    date: new FormControl<Date | null>(null, [Validators.required]),
    customer: new FormControl<string | Customer>('', Validators.required),
    accountNumber: new FormControl<string | null>(null),
    product: new FormControl<Product | null>(null),
    profile: new FormControl<string | null>(null, Validators.required),
    volume: new FormControl<number | null>(null, [Validators.required, Validators.pattern('^([0-9]+)$')]),
    price: new FormControl<number | null>(null, [Validators.required, Validators.pattern('^([0-9]+)$')]),
    signed: new FormControl(false),
    period: new FormGroup({
      from: new FormControl<Moment | null>(null, Validators.required),
      to: new FormControl<Moment | null>(null, Validators.required)
    }),
    periodTemplate: new FormControl<PeriodTemplate | null>(null, Validators.required),
    notes: new FormControl<string | null>(null)
  })

  submitting = false

  handleSubmit() {
    console.log('handle submit', this.contractForm.valid, this.contractForm)
    if (this.contractForm.valid) {
      const value = this.contractForm.value
      this.contractForm.disable()
      this.submitting = true
      setTimeout(() => {
        this.contractForm.enable()
        this.submitting = false
        this.addContract.emit(value)
      }, 3000);
    }
  }

  @ViewChild('contractNumber') contractNumber!: ElementRef;

  /** Kunde */
  customerOptions: Customer[] = [];
  //filteredCustomerOptions!: Observable<Customer[]>;
  gettingCustomers = false
  @ViewChild('customerSelect') customerSelect!: ElementRef;

  customerTimeout!: ReturnType<typeof setTimeout> 
  handleCustomerInput(e: Event) {
    clearTimeout(this.customerTimeout)
    this.customerTimeout = setTimeout(() => {
      this.getCustomers({filter: (e.target as HTMLInputElement).value}, true)
    }, 500);
  }

  getCustomers(params: Record<string, string>, focus = false, initialLoad = false) {
    if (initialLoad) this.contractForm.controls.customer.disable() 
    this.gettingCustomers = true
    this.customersService.getCustomers(params).subscribe({
      next: (data: Customer[]) => {
        if (initialLoad) this.contractForm.controls.customer.enable()
        this.gettingCustomers = false
        if (focus) this.customerSelect.nativeElement.focus()
        this.customerOptions = data
        //this.filterCustomerOptions()
      },
      error: (err) => {
        if (initialLoad) this.contractForm.controls.customer.enable()
        this.gettingCustomers = false
        console.log(err)
      }
    })
  }

  // filterCustomerOptions() {
  //   this.filteredCustomerOptions = this.myControl.valueChanges.pipe(
  //     startWith(''),
  //     map(value => {
  //       const name = typeof value === 'string' ? value : value?.name;
  //       const number = typeof value === 'string' ? value : value?.customerNumber;
  //       return name ? this._filter(name as string, number as string) : this.customerOptions.slice();
  //     }),
  //   );
  // }

  displayFn(customer: Customer): string {
    return customer && customer.name ? customer.name : '';
  }

  // private _filter(name: string, number: string): Customer[] {
  //   const filterValue = name.toLowerCase();
  //   return this.customerOptions.filter(option => {
  //     return option.name.toLowerCase().includes(filterValue) || option.customerNumber.includes(number)
  //   });
  // }

  /** account number */
  accountNumberOptions: string[] = []

  /** product */
  productGroups: Group[] = [];

  productGroupOptions!: Observable<Group[]>;

  private _filterGroup(value: string): Group[] {
    if (value) {
      return this.productGroups
        .map(group => ({name: group.name, products: this._filter(group.products, value)}))
        .filter(group => group.products.length > 0);
    }

    return this.productGroups;
  }

  _filter = (opt: GroupOption[], value: GroupOption | string): GroupOption[] => {
    const name = typeof value === 'string' ? value : value.displayName
    const filterValue = name.toLowerCase();
    return opt.filter(
      item => item.displayName.toLowerCase().includes(filterValue) ||
      item.prefix?.toLowerCase().includes(filterValue)
    );
  };

  productDisplayFn(item: {displayName: string}): string {
    return item && item.displayName ? item.displayName : '';
  }

  /* Profil */
  profiles: string[] = [];
  getProfiles() {
    return new Promise((resolve, reject) => {
      this.contractForm.controls.profile.disable()
      this.profilesService.getProfiles().subscribe({
        next: (data: string[]) => {
          resolve(true)
          this.contractForm.controls.profile.enable()
          this.profiles = data
        },
        error: (err) => {
          reject()
          this.contractForm.controls.profile.enable()
          console.log(err)
        }
      })
    })
  }

  /* Periodeskabelon */
  periods: PeriodTemplate[] = []
  getPeriodTemplates() {
    return new Promise((resolve, reject) => {
      this.contractForm.controls.periodTemplate.disable()
      this.periodTemplatesService.getPeriodTemplates().subscribe({
        next: (data: PeriodTemplate[]) => {
          resolve(true)
          this.contractForm.controls.periodTemplate.enable()
          this.periods = data
        },
        error: (err) => {
          reject()
          this.contractForm.controls.periodTemplate.enable()
          console.log(err)
        }
      })
    })
  }

  setFromDate(date: Moment, datepicker?: MatDatepicker<Moment>) {
    this.contractForm.controls.period.controls.from.setValue(date.startOf('month'))
    datepicker?.close()
  }

  setToDate(date: Moment, datepicker: MatDatepicker<Moment>) {
    this.contractForm.controls.period.controls.to.setValue(date.endOf('month'))
    datepicker?.close()
  }

  async ngOnInit() {
    this.contractsService.getProductGroups({}).subscribe({
      next: (data) => {
        if (data.body !== null) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.productGroups = data.body as Group[]
        }
      },
      error: (err) => {
        console.debug(err)
      }
    })
    this.getCustomers({}, false, true)
    this.contractForm.controls.profile.disable()
    this.contractForm.controls.periodTemplate.disable()
    this.productGroupOptions = this.contractForm.get('product')!.valueChanges.pipe(
      startWith(''),
      map(value => this._filterGroup(value as string || '')),
    );
    this.contractForm.controls.accountNumber.disable()
    this.contractForm.controls.period.controls.from.disable()
    this.contractForm.controls.period.controls.from.valueChanges.subscribe((value) => {
      if (value && value.date() !== 1) {
        this.contractForm.controls.period.controls.from.setValue(value.startOf('month'))
      }
    })
    this.contractForm.controls.period.controls.to.valueChanges.subscribe((value) => {
      if (value && value.date() !== value.daysInMonth()) {
        this.contractForm.controls.period.controls.to.setValue(value.endOf('month'))
      }
    })
    this.contractForm.controls.period.controls.to.disable()
    //this.contractForm.controls.profile.valueChanges.subscribe(() => {})
    this.contractForm.controls.periodTemplate.valueChanges.subscribe((value: PeriodTemplate | null) => {
      if (value?.canSetFromDate) {
        this.contractForm.controls.period.controls.from.enable()
      } else {
        this.contractForm.controls.period.controls.from.disable()
      }
      if (value?.canSetToDate) {
        this.contractForm.controls.period.controls.to.enable()
      } else {
        this.contractForm.controls.period.controls.to.disable()
      }
    })
    this.contractForm.controls.customer.valueChanges.subscribe((value) => {
      if (value && typeof value === 'object') {
        this.contractForm.controls.accountNumber.enable()
        this.accountNumberOptions = value.accounts
      } else {
        this.contractForm.controls.accountNumber.disable()
      }
    })
    /* get profiles, and period templates */
    this.getProfiles()
    await this.getPeriodTemplates()
  }
}