import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TeleportDirective } from '../../teleport.directive';
import { SidebarMenuItemComponent } from '../../components/sidebar-menu-item.component';

@Component({
  selector: 'app-sales',
  standalone: true,
  imports: [ TeleportDirective, RouterOutlet, SidebarMenuItemComponent],
  templateUrl: './sales.component.html',
  styleUrl: './sales.component.css'
})
export class SalesComponent {
  children = [
    {
      text: 'Olie',
      link: '/sales'
    },
    {
      text: 'El',
      link: '/sales/electricity'
    },
    {
      text: 'Gas',
      link: '/sales/gas'
    }
  ]
}
