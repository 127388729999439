import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { PeriodTemplate } from '../types';

@Injectable({
  providedIn: 'root'
})
export class PeriodTemplatesService {

  constructor(
    private http: HttpClient
  ) {}

  private handleError(error: ErrorEvent) {
    console.error('There was a problem', error)
    return throwError(() => new Error('Oops! Something went wrong. Please try again later.'));
  }

  getPeriodTemplates(): Observable<PeriodTemplate[]> {
    return this.http.get<PeriodTemplate[]>('/api/v1/periods').pipe(
      retry(3),
      catchError(this.handleError)
    )
  }
}
