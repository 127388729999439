<div class="flex flex-row justify-between items-end mb-4 gap-4">
  <h1 class="text-3xl text-secondary-dark">Salgsaftaler</h1>
  <button mat-flat-button (click)="openDialog()"><mat-icon>add</mat-icon>Tilføj salgsaftale</button>
  <!-- <button mat-flat-button (click)="showSidebar = true">Åbn sidebar</button> -->
</div>
<div
  class="grid gap-4 sm:grid-flow-col grid-flow-row sm:grid-rows-3 md:grid-rows-2 lg:grid-rows-1 w-full 2xl:w-auto mb-4">
  <mat-checkbox class="example-margin" [(ngModel)]="onlySigned">Vis kun salgsaftaler der mangler
    underskrift</mat-checkbox>
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="grow">
    <mat-label>Kontraktnr.</mat-label>
    <input matInput [(ngModel)]="filter" (input)="handleFilter($event)">
  </mat-form-field>
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="grow">
    <mat-label>Volumen fra</mat-label>
    <input matInput [(ngModel)]="volumeFrom" (input)="handleFilter($event)" type="number">
  </mat-form-field>
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="grow">
    <mat-label>Volumen til</mat-label>
    <input matInput [(ngModel)]="volumeTo" (input)="handleFilter($event)" type="number">
  </mat-form-field>
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="grow">
    <mat-label>Salgspris fra</mat-label>
    <input matInput [(ngModel)]="priceFrom" (input)="handleFilter($event)" type="number">
  </mat-form-field>
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="grow">
    <mat-label>Salgspris til</mat-label>
    <input matInput [(ngModel)]="priceTo" (input)="handleFilter($event)" type="number">
  </mat-form-field>
</div>
<app-card [noPadding]="true" class="relative">
  @if (isLoadingResults) {
  <div class="absolute top-0 left-0 bottom-0 right-0 bg-black/15 z-[1] flex items-center justify-center">
    <mat-spinner diameter="40"></mat-spinner>
  </div>
  }
  <table mat-table [dataSource]="contracts" matSort (matSortChange)="handleSort($event)" multiTemplateDataRows
    [matSortActive]="sorting.active" [matSortDirection]="sorting.direction">

    <!-- <ng-container matColumnDef="check">
      <th mat-header-cell *matHeaderCellDef class="w-[50px]">
        <mat-checkbox (change)="$event ? toggleAllRows() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container> -->

    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Kontraktnummer </th>
      <td mat-cell *matCellDef="let element"> {{element.contractNumber}} </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Dato </th>
      <td mat-cell *matCellDef="let element"> {{datePipe.transform(element.date,
        'dd.MM.yyyy')}}</td>
    </ng-container>

    <ng-container matColumnDef="customer">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Kundenavn </th>
      <td mat-cell *matCellDef="let element"> {{element.customer.name}} </td>
    </ng-container>

    <ng-container matColumnDef="account">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Kontonummer </th>
      <td mat-cell *matCellDef="let element"> {{element.accountNumber}} </td>
    </ng-container>

    <ng-container matColumnDef="product">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Produkt </th>
      <td mat-cell *matCellDef="let element"> {{element.product.displayName}} </td>
    </ng-container>

    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Fastpris </th>
      <td mat-cell *matCellDef="let element"> {{element.price.toLocaleString('da-DK')}} </td>
    </ng-container>

    <ng-container matColumnDef="volume">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [innerHTML]="'Volumen (m3)' | cbm"></th>
      <td mat-cell *matCellDef="let element"> {{element.volume.toLocaleString('da-DK')}}
      </td>
    </ng-container>

    <ng-container matColumnDef="profile">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Profil </th>
      <td mat-cell *matCellDef="let element"> {{element.profile }} </td>
    </ng-container>

    <ng-container matColumnDef="period">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Periode </th>
      <td mat-cell *matCellDef="let element"> {{element.periodTemplate.displayName }} </td>
    </ng-container>

    <ng-container matColumnDef="customerManager">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Ansvarlig </th>
      <td mat-cell *matCellDef="let element"> {{element.customerManager }} </td>
    </ng-container>

    <ng-container matColumnDef="signed">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Underskrevet </th>
      <td mat-cell *matCellDef="let element">
        @if (element.signed) {
        <mat-icon aria-hidden="false" aria-label="signed" fontIcon="check" class="tertiary-icon"></mat-icon>
        } @else {
        <mat-icon aria-hidden="false" aria-label="not signed" fontIcon="close" class="primary-icon"></mat-icon>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="covered">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Afdækket </th>
      <td mat-cell *matCellDef="let element">
        @if (element.covered) {
        <mat-icon aria-hidden="false" aria-label="covered" fontIcon="check" class="tertiary-icon"></mat-icon>
        } @else {
        <mat-icon aria-hidden="false" aria-label="not covered" fontIcon="close" class="primary-icon"></mat-icon>
        }
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" (click)="selectElement(element)"></tr>
  </table>
  <mat-paginator #paginator class="demo-paginator border-t" (page)="handlePageEvent($event)" [length]="length"
    [pageSize]="pageSize" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
    [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize" [pageIndex]="pageIndex"
    aria-label="Select page">
  </mat-paginator>
</app-card>

<app-sidebar [show]="showSidebar" (emitClose)="closeDialog()"
  [title]="selectedElement ? 'Salgsaftale ' + selectedElement.contractNumber : 'Tilføj salgsaftale'">
  <app-add-contract (addContract)="saveContract($event)" (cancel)="closeDialog()" [data]="selectedElement"
    [show]="showSidebar"></app-add-contract>
</app-sidebar>