import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from "@angular/common/http";
import { catchError, Observable, retry, throwError } from 'rxjs';
import { Contract } from '../types';
import { Confirmation } from '../views/procurement/confirmations/confirmations.component';
import { Order } from '../views/procurement/agreements/agreements.component';
import { Group } from '../components/products-autocomplete/products-autocomplete.component';

interface Counterparty {
  id: string;
  displayName: string;
}

export interface EmailTemplates {
  emailTemplates: {
    profile: string;
    subject: string;
    message: string
  }[];
  table: string;
  manualTable: string
}

export interface SummarizedContracts {
  period: string
  start: string
  end: string
  volumeB0: number
  volumeB7: number
  sum: number
}

@Injectable({
  providedIn: 'root'
})
export class ContractsService {

  constructor(
    private http: HttpClient
  ) {}

  private handleError(error: ErrorEvent) {
    console.error('There was a problem', error)
    return throwError(() => new Error('Oops! Something went wrong. Please try again later.'));
  }

  getContracts(params: Record<string, string | number>): Observable<{total: number; results: Contract[]}> {
    return this.http.get<{total: number; results: Contract[]}>('/api/v1/contracts', {params: params}).pipe(
      retry(3),
      catchError(this.handleError)
    )
  }

  getContract(id: string): Observable<Contract> {
    return this.http.get<Contract>(`/api/v1/contracts/${id}`).pipe(
      retry(3),
      catchError(this.handleError)
    )
  }

  getSalesAgreements(params: Record<string, string | number>): Observable<HttpResponse<Contract[]>> {
    return this.http.get<Contract[]>('/api/v1/sales-agreements', {observe: 'response', params: params}).pipe(
      retry(3),
      catchError(this.handleError)
    )
  }

  getConfirmations(params: Record<string, string | number>): Observable<HttpResponse<Confirmation[]>> {
    return this.http.get<Confirmation[]>('/api/v1/confirmations', {observe: 'response', params: params}).pipe(
      retry(3),
      catchError(this.handleError)
    )
  }

  getAgreements(params: Record<string, string | number>): Observable<HttpResponse<Order[]>> {
    return this.http.get<Order[]>('/api/v1/agreements', {observe: 'response', params: params}).pipe(
      retry(3),
      catchError(this.handleError)
    )
  }

  getCounterparties(params: Record<string, string | number>): Observable<HttpResponse<Counterparty[]>> {
    return this.http.get<Counterparty[]>('/api/v1/counterparties', {observe: 'response', params: params}).pipe(
      retry(3),
      catchError(this.handleError)
    )
  }

  getProductGroups(params: Record<string, string | number>): Observable<HttpResponse<Group[]>> {
    return this.http.get<Group[]>('/api/v1/product-groups', {observe: 'response', params: params}).pipe(
      retry(3),
      catchError(this.handleError)
    )
  }

  getEmailTemplates(params: Record<string, string | number>): Observable<HttpResponse<EmailTemplates>> {
    return this.http.get<EmailTemplates>('/api/v1/email-templates', {observe: 'response', params: params}).pipe(
      retry(3),
      catchError(this.handleError)
    )
  }

  getSummarizedContracts(params: Record<string, string | number>): Observable<HttpResponse<SummarizedContracts[]>> {
    return this.http.get<SummarizedContracts[]>('/api/v1/summarized-contracts', {observe: 'response', params: params}).pipe(
      retry(3),
      catchError(this.handleError)
    )
  }
}
