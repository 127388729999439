import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { catchError, Observable, retry } from 'rxjs';
import { throwError } from 'rxjs';
import { Customer } from '../types';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  constructor(
    private http: HttpClient
  ) {}

  private handleError(error: ErrorEvent) {
    console.error('There was a problem', error)
    return throwError(() => new Error('Oops! Something went wrong. Please try again later.'));
  }

  getCustomers(params: Record<string, string>): Observable<Customer[]> {
    return this.http.get<Customer[]>('/api/v1/customers', {params: params}).pipe(
      retry(3),
      catchError(this.handleError)
    )
  }
}
