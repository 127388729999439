import { Component, Input } from "@angular/core";

@Component({
  selector: 'app-card',
  template: `
    <div class="bg-white border rounded shadow overflow-hidden" [class]="[noPadding ? '' : 'p-4', cardClass]">
      <ng-content />
    </div>
  `,
  styles: '',
  standalone: true,
  imports: [],
})

export class CardComponent {
  @Input() noPadding?: boolean
  @Input() cardClass?: string
}