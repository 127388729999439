import { Routes } from '@angular/router';
import { FailedComponent } from './views/failed/failed.component';
import { HomeComponent } from './views/home/home.component';
import { ProfileComponent } from './views/profile/profile.component';
import { MsalGuard } from '@azure/msal-angular';
import { ProcurementComponent } from './views/procurement/procurement.component';
import { SalesComponent } from './views/sales/sales.component';
import { TradeLinesComponent } from './views/credit-lines/credit-lines.component';
import { ReportingComponent } from './views/reporting/reporting.component';
import { SettingsComponent } from './views/settings/settings.component';
import { permissionGuard } from './guards/permission.guard';
import { AgreementsComponent } from './views/procurement/agreements/agreements.component';
import { ConfirmationsComponent } from './views/procurement/confirmations/confirmations.component';
import { DashboardComponent } from './views/procurement/dashboard/dashboard.component';
import { NetPositionsComponent } from './views/reporting/net-positions/net-positions.component';
import { OilComponent } from './views/sales/oil/oil.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'procurement',
    component: ProcurementComponent,
    data: { permission: 'procurement:view', sidebar: true },
    canActivate: [MsalGuard, permissionGuard],
    children: [
      {
        path: '',
        component: DashboardComponent,
        data: { permission: 'procurement:view' },
        canActivate: [MsalGuard, permissionGuard],
      },
      {
        path: 'agreements',
        component: AgreementsComponent,
        data: { permission: 'procurement:view' },
        canActivate: [MsalGuard, permissionGuard],
      },
      {
        path: 'confirmations',
        component: ConfirmationsComponent,
        data: { permission: 'procurement:view' },
        canActivate: [MsalGuard, permissionGuard],
      },
    ]
  },
  {
    path: 'sales',
    component: SalesComponent,
    data: { permission: 'sales:view', sidebar: true },
    canActivate: [MsalGuard, permissionGuard],
    children: [
      {
        path: '',
        component: OilComponent,
        data: { permission: 'sales:view' },
        canActivate: [MsalGuard, permissionGuard],
      },
    ]
  },
  {
    path: 'credit-lines',
    component: TradeLinesComponent,
    data: { permission: 'controlling:view' },
    canActivate: [MsalGuard, permissionGuard],
  },
  {
    path: 'reporting',
    component: ReportingComponent,
    data: { permission: 'reporting:view', sidebar: true },
    canActivate: [MsalGuard, permissionGuard],
    children: [
      {
        path: '',
        component: NetPositionsComponent,
        data: { sidebar: true },
      }
    ]
  },
  {
    path: 'settings',
    component: SettingsComponent,
    data: { permission: 'administration:view', sidebar: true },
    canActivate: [MsalGuard, permissionGuard],
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'login-failed',
    component: FailedComponent,
  },
  {
    path: 'sign-in',
    redirectTo: ''
  }
];
