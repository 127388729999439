import { Component } from "@angular/core";

@Component({
  selector: 'app-logo',
  template: `
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 66.6 44.6"
      style="enable-background: new 0 0 250.1 44.6"
      xml:space="preserve"
      class="h-[40px] w-auto"
    >
      <path
        class="st0"
        style="fill-rule: evenodd; clip-rule: evenodd; fill: #db1f26"
        d="M29.3 34.4c.2 0 .5-.1.7-.1.2-.1.4-.2.6-.4.2-.1.3-.3.5-.5.3-.4.5-.8.6-1.2.1-.2.2-.5.2-.7l4.5-18.8c.1-.3.1-.7.1-1.1 0-.2-.1-.4-.1-.5-.1-.2-.2-.3-.3-.5-.1-.1-.2-.2-.3-.2-.2-.2-.5-.3-.8-.3l.2-.8H48l-.2.9c-.2 0-.4 0-.6.1-.2.1-.4.1-.6.2-.1.1-.3.2-.4.3-.1.1-.2.2-.3.4-.1.1-.2.3-.2.4-.1.1-.1.3-.1.5L43.4 21l11.8-9.4.3-.3.3-.3c.1-.2.1-.4 0-.6 0-.1-.1-.1-.1-.2-.1-.1-.3-.2-.4-.2h-.2l.2-.8h11.2l-.2.9c-.3 0-.6.1-.9.2-.3.1-.6.2-.8.3-.3.2-.6.3-.9.5l-.9.6L52.4 20l8 10.9c.3.3.5.7.8 1 .3.3.6.7.8 1l.5.5c.2.1.4.3.5.4.1.1.3.2.4.2.1.1.3.1.4.2l-.2.7c-1.8.5-3.6.6-5.5.6-.7 0-1.4-.1-2-.2-.5-.1-1-.2-1.5-.4s-1-.4-1.4-.6c-.3-.1-.5-.3-.7-.5-.2-.2-.5-.4-.7-.6-.2-.2-.4-.5-.6-.7-.2-.2-.4-.5-.6-.7l-7.4-10.4L40.8 32c-.1.4-.1.8-.1 1.2 0 .1 0 .2.1.3 0 .1.1.2.2.3.1.1.1.2.2.2.2.1.3.2.5.3.2.1.3.1.5.1l-.2.7H29.1l.2-.7zM.3 20.8c.1-.8.4-1.5.6-2.2.2-.7.5-1.3.9-1.9.3-.6.8-1.2 1.2-1.7.5-.6 1-1.2 1.6-1.7.6-.5 1.2-1 1.8-1.4.6-.4 1.2-.8 1.8-1.1.6-.3 1.3-.6 2-.9.6-.2 1.2-.4 1.9-.6 1.7-.5 3.5-.6 5.2-.6 1.6 0 3.1.1 4.7.6.5.2 1 .3 1.5.5s1 .4 1.5.7c.5.3 1 .6 1.4.9.5.3.9.7 1.3 1.1l1.2 1.2c.4.4.7.9 1 1.4.3.5.6 1 .8 1.5.4 1 .7 2 .8 3.1.1 1.2.1 2.4-.1 3.6-.1.6-.2 1.1-.3 1.6-.2.5-.3 1-.6 1.6-.3.8-.8 1.5-1.2 2.2-.5.7-1 1.4-1.6 2-.6.6-1.2 1.2-1.9 1.7s-1.4 1-2.2 1.4c-.7.4-1.4.7-2.1.9-1.5.6-3.2.9-4.8 1.1-1.9.2-3.8.2-5.7-.1-.6-.1-1.2-.2-1.8-.4-.6-.2-1.2-.4-1.7-.6-.6-.2-1.1-.5-1.7-.9-.5-.3-1-.7-1.5-1.1-.5-.4-.9-.8-1.3-1.3-.4-.4-.8-.9-1.2-1.4-.3-.4-.5-.9-.8-1.4-.4-.8-.7-1.6-.8-2.4-.2-1-.2-2.1-.2-3.1 0-.8.1-1.5.3-2.3m13.1 10.6c1 0 2-.1 3-.4.3-.1.6-.2.9-.4.4-.2.8-.4 1.1-.7.3-.3.7-.6.9-1 .4-.5.7-1 1-1.6.3-.6.6-1.1.8-1.7.2-.6.4-1.3.6-1.9.2-.6.3-1.3.5-1.9.2-1.1.4-2.2.4-3.3 0-.7 0-1.4-.2-2.1-.1-.3-.2-.7-.4-1-.2-.3-.4-.6-.7-.8-.3-.3-.7-.6-1.1-.8-.4-.2-.8-.3-1.3-.4-.5-.1-1-.1-1.4-.1-1 0-2 .2-2.8.6-.5.2-.9.4-1.3.7-.3.2-.6.5-.9.8-.3.3-.5.6-.7 1-.3.5-.6 1.1-.8 1.6-.3.5-.5 1.1-.7 1.7l-.6 1.8c-.3 1-.5 2-.6 3-.1.7-.2 1.4-.1 2.1 0 .6.1 1.2.3 1.8.1.3.2.6.4.8l.6.9c.2.3.5.5.8.7.4.2.7.4 1.1.5.4 0 .8.1 1.2.1"
      />
    </svg>
  `,
  styles: '',
  standalone: true,
  imports: [],
})

export class LogoComponent {}