import { Component, OnInit } from '@angular/core';
import { CardComponent } from '../../../components/card.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { Contract } from '../../../types';
import { SidebarComponent } from "../../../components/sidebar.component";
import { AddPriceContractDialogComponent } from "../dashboard/create.component";
import { ContractsService } from '../../../services/contracts.service';

export interface Order {
  id: string;
  date: Date;
  counterparty: {
    id: string;
    displayName: string;
  },
  confirmed: boolean;
  confirmation: null | {
    id: string
  },
  coveredContracts: Contract[]
}

@Component({
  selector: 'app-agreements',
  standalone: true,
  imports: [CardComponent, MatProgressSpinnerModule, MatTableModule, MatIcon, MatSortModule, MatPaginatorModule, SidebarComponent, AddPriceContractDialogComponent],
  templateUrl: './agreements.component.html',
  styleUrl: './agreements.component.css',
  providers: [DatePipe]
})
export class AgreementsComponent implements OnInit {
  constructor(
    public datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private contractsService: ContractsService
  ){}

  isLoadingResults = false
  showSidebar = false
  orders: Order[] = []
  columns = ['id', 'date', 'counterparty', 'confirmed']
  selectedElement: Order | null = null;

  selectElement(element: Order) {
    this.router.navigate([], {
      queryParams: {
        order: element.id
      },
      queryParamsHandling: 'merge',
    });
    this.selectedElement = element
    this.openDialog()
  }

  data: {
    title: string;
    contracts: Contract[],
    order?: Order
  } = {
    title: '',
    contracts: [],
    order: undefined
  }

  openDialog() {
    this.showSidebar = true
    this.data.title = `Prissikringsordre ${this.selectedElement?.id}`
    this.data.contracts = this.selectedElement?.coveredContracts as Contract[]
    this.data.order = this.selectedElement!
  }

  closeDialog() {
    this.showSidebar = false
    this.selectedElement = null
    this.router.navigate([], {
      queryParams: {
        order: null
      },
      queryParamsHandling: 'merge',
    })
  }

  /** Sorting */
  sorting: Sort = {
    active: 'date',
    direction: 'desc'
  }

  handleSort(e: Sort) {
    console.log(e);
    this.sorting = e;
  }

  /** Pagination */
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  paginationDisabled = false;
  length = 2;
  pageSize = 15;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 15, 20];
  pageEvent: PageEvent | null = null;
  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    /* TODO: get results */
  }

  ngOnInit(): void {
    this.contractsService.getAgreements({}).subscribe({
      next: (data) => {
        this.orders = data.body as Order[]
      },
      error: (err) => {
        console.debug(err)
      }
    })
    if (this.route.snapshot.queryParamMap.get('order')) {
      const id = this.route.snapshot.queryParamMap.get('order')
      /* TODO: get order from api */
      this.selectedElement = this.orders.find((e) => e.id === id) as Order
      this.openDialog()
    }
  }
}
