import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { provideMomentDateAdapter } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { DatePipe } from '@angular/common';
import { CardComponent } from "../../../components/card.component";
import { MatIconModule } from "@angular/material/icon";
//import { Contract } from "../../../types";
import { Order } from "../agreements/agreements.component";
import { CbmPipe } from "../../../cbm.pipe";
import { SafeHtmlPipe } from "../../../safe-html.pipe";
import { Contract } from "../../../types";
import { ContractsService, EmailTemplates } from "../../../services/contracts.service";

export interface Counterparty {
  id: string;
  displayName: string;
}

@Component({
  selector: 'app-add-price-contract-dialog',
  standalone: true,
  imports: [
    MatProgressSpinner,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSelectModule,
    MatTableModule,
    CardComponent,
    MatIconModule,
    CbmPipe,
    SafeHtmlPipe
  ],
  templateUrl: './create.component.html',
  styleUrl: './create.component.css',
  providers: [DatePipe, provideMomentDateAdapter(), {provide: MAT_DATE_LOCALE, useValue: 'da-DK'}]
})
export class AddPriceContractDialogComponent implements OnChanges {
  //@Input() data: {title: string, contracts: Contract[], order?: Order, profile?: string} | null = null
  @Input() order?: Order | null = null
  @Input() contracts: Contract[] = []
  @Input() profile = ''
  @Output() add = new EventEmitter()
  @Output() cancel = new EventEmitter()
  loading = false
  displayedColumns = ['customer', 'account', 'product', 'price', 'volume', 'profile', 'period', 'managedBy', 'signed']

  createForm = new FormGroup({
    date: new FormControl<Date>(new Date(), [Validators.required]),
    counterparty: new FormControl<Counterparty | null>(null, [Validators.required])
  })

  counterparties: Counterparty[] = []
  emailTemplates: { profile: string; subject: string; message: string; }[] = []
  table = ''
  manualTable = ''

  getEmailTemplate(profile: string) {
    const template = {...this.emailTemplates.find((e) => e.profile === profile)} as {profile: string; subject: string; message: string}
    if (template) {
      template.subject = template.subject?.replace(/<DATE>/g, new Date().toLocaleDateString())
      template.message = template.message?.replace(/<TABLE>/g, profile === 'Landbrugsprofil' ? this.table : this.manualTable)
      return template
    }
    return {subject: '', message: ''}
  }

  constructor(
    public datePipe: DatePipe,
    private contractsService: ContractsService
  ){}

  handleSubmit() {
    console.log('submit')
  }

  getVolumeSum() {
    let volume = 0
    if (this.order) {
      for (const item of this.order.coveredContracts) {
        volume += item.volume
      }
    }
    return volume
  }

  ngOnChanges(changes: SimpleChanges) {
    this.contractsService.getCounterparties({}).subscribe({
      next: (data) => {
        this.counterparties = data.body as Counterparty[]
      },
      error: (err) => {
        console.debug(err)
      }
    })
    if(changes['order']) {
      if (this.order) {
        this.createForm.controls.counterparty.setValue(this.counterparties.find((e) => e.id === this.order?.counterparty.id) as {id: string; displayName: string;})
      }
    }
    this.contractsService.getEmailTemplates({}).subscribe({
      next: (data) => {
        this.emailTemplates = (data.body as EmailTemplates).emailTemplates
        this.table = (data.body as EmailTemplates).table
        this.manualTable = (data.body as EmailTemplates).manualTable
      },
      error: (err) => {
        console.debug(err)
      }
    })
  }
}