import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'app-sidebar',
  template: `
    <div
    class="fixed p-4 bg-white shadow-lg left-full bottom-0 border-l w-full max-w-[1280px] z-[10] transition-transform overflow-auto"
    [class]="[ show ? 'translate-x-[-100%]' : 'translate-x-0']" [style]="'top:' + top + 'px'">
      @if(title) {
      <div class="sticky top-0 left-0 right-0 border-b -ml-4 -translate-y-4 -mr-4 p-4 bg-white z-10">
        <h2 class="text-xl text-secondary-dark">{{title}}</h2>
      </div>
      }
      <ng-content />
    </div>
    @if (show) {
    <div class="fixed bg-black/25 left-0 right-0 top-0 bottom-0 z-[9]" (mouseup)="emitClose.emit()"></div>
    }
  `,
  styles: ``,
  standalone: true,
  imports: [],
})

export class SidebarComponent {
  @Input({ required: true }) show!: boolean;
  @Input() top?: number = 60;
  @Input() title?: string
  @Output() emitClose = new EventEmitter()
}