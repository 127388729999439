import { Component } from '@angular/core';
import {  RouterOutlet } from '@angular/router';
import { TeleportDirective } from '../../teleport.directive';
import { MatButtonModule } from '@angular/material/button';
import { SidebarMenuItemComponent } from '../../components/sidebar-menu-item.component';

@Component({
  selector: 'app-procurement',
  standalone: true,
  imports: [RouterOutlet, TeleportDirective, MatButtonModule, SidebarMenuItemComponent],
  templateUrl: './procurement.component.html',
  styleUrl: './procurement.component.css',
})
export class ProcurementComponent {
  children = [
    {
      type: 'header',
      text: 'Olie'
    },
    {
      text: 'Dagens overblik',
      link: '/procurement'
    },
    {
      text: 'Prissikringsordrer',
      link: '/procurement/agreements'
    },
    {
      text: 'Handelsbekræftelser',
      link: '/procurement/confirmations'
    },
    {
      type: 'header',
      text: 'El'
    },
    {
      text: 'Dagens overblik',
      link: '/procurement/electricity'
    },
    {
      text: 'Prissikringsordrer',
      link: '/procurement/electricity/agreements'
    },
    {
      text: 'Handelsbekræftelser',
      link: '/procurement/electricity/confirmations'
    },
    {
      type: 'header',
      text: 'Gas'
    },
    {
      text: 'Dagens overblik',
      link: '/procurement/gas'
    },
    {
      text: 'Prissikringsordrer',
      link: '/procurement/gas/agreements'
    },
    {
      text: 'Handelsbekræftelser',
      link: '/procurement/gas/confirmations'
    }
  ]
  handleActivate() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }
}
