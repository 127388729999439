import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TeleportDirective } from '../../teleport.directive';
import { SidebarMenuItemComponent } from '../../components/sidebar-menu-item.component';

@Component({
  selector: 'app-reporting',
  standalone: true,
  imports: [ TeleportDirective, RouterOutlet, SidebarMenuItemComponent],
  templateUrl: './reporting.component.html',
  styleUrl: './reporting.component.css'
})
export class ReportingComponent {
  children = [
    {
      text: 'Nettopositioner',
      link: '/reporting'
    }
  ]
}
