<h1 class="hidden">Dagens overblik</h1>
<div class="flex flex-row justify-between items-end mb-4 gap-4">
  <h2 class="text-3xl text-secondary-dark">Uafdækkede salgsaftaler</h2>
</div>
<app-card [noPadding]="true" class="relative">
  @if (isLoadingResults) {
  <div class="absolute top-0 left-0 bottom-0 right-0 bg-black/15 z-[1] flex items-center justify-center">
    <mat-spinner diameter="40"></mat-spinner>
  </div>
  }
  <table mat-table [dataSource]="contracts" matSort (matSortChange)="handleSort($event)" multiTemplateDataRows
    [matSortActive]="sorting.active" [matSortDirection]="sorting.direction">

    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Kontraktnummer </th>
      <td mat-cell *matCellDef="let element">
        {{element.contractNumber}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Dato </th>
      <td mat-cell *matCellDef="let element">
        {{datePipe.transform(element.date, 'dd.MM.yyyy')}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="customer">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Kundenavn </th>
      <td mat-cell *matCellDef="let element">
        {{element.customer.name}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="account">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Kontonummer </th>
      <td mat-cell *matCellDef="let element">
        {{element.accountNumber}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="product">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Produkt </th>
      <td mat-cell *matCellDef="let element">
        {{element.product.displayName}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="volume">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Volumen&nbsp;<span [innerHTML]="'(m3)' | cbm"></span> </th>
      <td mat-cell *matCellDef="let element">
        {{element.volume.toLocaleString('da-DK')}}
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <span class="flex justify-end gap-10">
          <span>I alt</span>
          <span>{{getVolumeSum()}}</span>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="profile">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Profil </th>
      <td mat-cell *matCellDef="let element">
        {{element.profile }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="period">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Periode </th>
      <td mat-cell *matCellDef="let element">
        {{element.periodTemplate.displayName }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Salgspris </th>
      <td mat-cell *matCellDef="let element">
        {{element.price.toLocaleString('da-DK')}}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="managedBy">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Ansvarlig </th>
      <td mat-cell *matCellDef="let element">
        {{element.customerManager }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="signed">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Underskrevet </th>
      <td mat-cell *matCellDef="let element">
        @if (element.signed) {
        <mat-icon aria-hidden="false" aria-label="signed" fontIcon="check" class="tertiary-icon"></mat-icon>
        } @else {
        <mat-icon aria-hidden="false" aria-label="not signed" fontIcon="close" class="primary-icon"></mat-icon>
        }
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  </table>
</app-card>

<div class="flex flex-row justify-between items-end mb-4 gap-4 mt-8">
  <h2 class="text-3xl text-secondary-dark">Opsummering af uafdækkede salgsaftaler på landbrugsprofilen</h2>
</div>

<app-card [noPadding]="true">
  <table mat-table [dataSource]="summarizedContracts">
    <ng-container matColumnDef="period">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element"> {{element.period}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="start">
      <th mat-header-cell *matHeaderCellDef> Start </th>
      <td mat-cell *matCellDef="let element"> {{datePipe.transform(element.start, 'dd.MM.yyyy')}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="end">
      <th mat-header-cell *matHeaderCellDef> Slut </th>
      <td mat-cell *matCellDef="let element"> {{datePipe.transform(element.end, 'dd.MM.yyyy')}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="volumeB0">
      <th mat-header-cell *matHeaderCellDef> B0 volumen <span [innerHTML]="'(m3)' | cbm"></span></th>
      <td mat-cell *matCellDef="let element"> {{element.volumeB0}} </td>
      <td mat-footer-cell *matFooterCellDef>
        <div class="flex justify-end gap-10 text-right">
          <span>I alt</span>
          <span>{{getB0Volume()}}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="volumeB7">
      <th mat-header-cell *matHeaderCellDef> B7 volumen <span [innerHTML]="'(m3)' | cbm"></span></th>
      <td mat-cell *matCellDef="let element"> {{element.volumeB7}} </td>
      <td mat-footer-cell *matFooterCellDef>
        {{getB7Volume()}}
      </td>
    </ng-container>

    <ng-container matColumnDef="sum">
      <th mat-header-cell *matHeaderCellDef> I alt <span [innerHTML]="'(m3)' | cbm"></span></th>
      <td mat-cell *matCellDef="let element">{{element.sum}}</td>
      <td mat-footer-cell *matFooterCellDef> {{getSummaryVolumeSum()}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="summaryColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: summaryColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="summaryColumns"></tr>
  </table>
</app-card>
<div class="flex justify-end mt-4">
  <button mat-flat-button (click)="openDialog(filteredContracts(), 'Landbrugsprofil')">Opret prissikringsordre</button>
</div>

<div class="flex flex-row justify-between items-end mb-4 gap-4 mt-8">
  <h2 class="text-3xl text-secondary-dark">Dagens salgsaftaler på manuel profil, B0</h2>
</div>

@if (manualContractsB0().length) {
<app-card>
  @for (contract of manualContractsB0(); track $index) {
  <div>
    <h4 class="text-lg font-bold text-secondary">{{contract.customer.name}}</h4>
  </div>
  }
</app-card>
} @else {
<span class="text-secondary-lighter italic">Ingen salgsaftaler</span>
}
<div class="flex justify-end mt-4">
  <button mat-flat-button (click)="openDialog(manualContractsB0(), 'Manuel')"
    [disabled]="!manualContractsB0().length">Opret
    prissikringsordre</button>
</div>

<div class="flex flex-row justify-between items-end mb-4 gap-4 mt-8">
  <h2 class="text-3xl text-secondary-dark">Dagens salgsaftaler på manuel profil, B7</h2>
</div>

@if (manualContractsB7().length) {
<app-card>
  @for (contract of manualContractsB7(); track $index) {
  <div>
    <h2 class="text-xl font-bold text-secondary-dark mb-2">{{contract.customer.name}}</h2>
    <h3 class="text-secondary-dark text-lg font-bold mb-2">2025</h3>
    <div class="flex w-full gap-2">
      <fieldset class="px-4 pb-2 pt-1 grow">
        <legend class="text-xs">JAN.</legend>
        50
      </fieldset>
      <fieldset class="px-4 pb-2 pt-1 grow">
        <legend class="text-xs">FEB.</legend>
        50
      </fieldset>
      <fieldset class="px-4 pb-2 pt-1 grow">
        <legend class="text-xs">MAR.</legend>
        50
      </fieldset>
      <fieldset class="px-4 pb-2 pt-1 grow">
        <legend class="text-xs">APR.</legend>
        50
      </fieldset>
      <fieldset class="px-4 pb-2 pt-1 grow">
        <legend class="text-xs">MAJ</legend>
        50
      </fieldset>
      <fieldset class="px-4 pb-2 pt-1 grow">
        <legend class="text-xs">JUN.</legend>
        50
      </fieldset>
      <fieldset class="px-4 pb-2 pt-1 grow">
        <legend class="text-xs">JUL.</legend>
        50
      </fieldset>
      <fieldset class="px-4 pb-2 pt-1 grow">
        <legend class="text-xs">AUG.</legend>
        50
      </fieldset>
      <fieldset class="px-4 pb-2 pt-1 grow">
        <legend class="text-xs">SEP.</legend>
        50
      </fieldset>
      <fieldset class="px-4 pb-2 pt-1 grow">
        <legend class="text-xs">OKT.</legend>
        50
      </fieldset>
      <fieldset class="px-4 pb-2 pt-1 grow">
        <legend class="text-xs">NOV.</legend>
        50
      </fieldset>
      <fieldset class="px-4 pb-2 pt-1 grow">
        <legend class="text-xs">DEC.</legend>
        50
      </fieldset>
      <fieldset class="px-4 pb-2 pt-1 border-0">
        <legend class="text-xs">I alt</legend>
        <span class="font-bold" [innerHTML]="'600 m3' | cbm"></span>
      </fieldset>
    </div>
  </div>
  }
</app-card>
} @else {
<span class="text-secondary-lighter italic">Ingen salgsaftaler</span>
}
<div class="flex justify-end mt-4">
  <button mat-flat-button (click)="openDialog(manualContractsB7(), 'Manuel')"
    [disabled]="!manualContractsB7().length">Opret
    prissikringsordre</button>
</div>

<app-sidebar [show]="showSidebar" (emitClose)="showSidebar = false" [title]="data.title">
  <app-add-price-contract-dialog [contracts]="data.contracts" [profile]="data.profile!"
    (cancel)="showSidebar = false"></app-add-price-contract-dialog>
</app-sidebar>