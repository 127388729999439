import { Component, OnInit } from '@angular/core';
import { CardComponent } from "../../../components/card.component";
import { EChartsOption, SliderDataZoomComponentOption } from 'echarts';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-net-positions',
  standalone: true,
  imports: [CardComponent, NgxEchartsDirective],
  templateUrl: './net-positions.component.html',
  styleUrl: './net-positions.component.css',
  providers: [provideEcharts()]
})
export class NetPositionsComponent implements OnInit {
  selectedYearFrom = 2023
  selectedYearTo = 2027
  oilNetPosition: EChartsOption = {}
  oilSettings = {
    unit: 'm³',
    marginLeft: 60
  }

  constructor(
    private http: HttpClient
  ){}

  dataZoomStyling = {
    backgroundColor: 'white',
    dataBackground: {
      lineStyle: {
        color: '#ce0011'
      },
      areaStyle: {
        color: '#ce0011',
        opacity: .05
      }
    },
    selectedDataBackground: {
      lineStyle: {
        color: '#ce0011'
      },
      areaStyle: {
        color: '#ce0011',
        opacity: .25
      }
    },
    borderColor: '#cccccc',
    fillerColor: 'rgba(0,0,0,.1)',
    handleStyle: {
      color: '#ccc',
      borderColor: '#aaa',
    },
    moveHandleStyle: {
      color: '#ccc',
      borderColor: '#aaa'
    },
    emphasis: {
      handleStyle: {
        color: '#ce0011',
        borderColor: '#ce0011',
      },
      moveHandleStyle: {
        color: '#ce0011',
        borderColor: '#ce0011',
      }
    }
  }

  dataZoom = [
    {
      id: 'dataZoomX',
      type: 'slider',
      xAxisIndex: [0, 1],
      startValue: `jan. ${this.selectedYearFrom}`,
      endValue: `dec. ${this.selectedYearTo}`,
      filterMode: 'filter',
      realtime: false,
      ...this.dataZoomStyling
    }
  ] as SliderDataZoomComponentOption

  oilNetPositionData: (string | number)[][] = []
  showCharts = false

  ngOnInit(): void {
      this.http.get<(string | number)[][]>('/api/v1/net-positions').subscribe((resp) => {
        this.oilNetPositionData = resp
        
        this.oilNetPosition = {
          tooltip: {
            trigger: 'axis'
          },
          grid: {
            left: 50,
            right: 20,
            bottom: 70,
            top: 60,
          },
          xAxis: {
            data: this.oilNetPositionData.map(function (item) {
              return new Date(item[0]).toLocaleDateString('da-DK', {year: 'numeric', month: 'short',})
            })
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: (value) => { return `${value.toLocaleString('da-DK')} ${this.oilSettings.unit}` }
            }
          },
          toolbox: {
            right: 10,
            feature: {
              saveAsImage: {}
            }
          },
          dataZoom: this.dataZoom,
          visualMap: {
            orient: 'horizontal',
            top: 30,
            left: 0,
            pieces: [
              {
                min: -0.01,
                max: 0.01,
                color: '#999',
                label: 'Balancerer'
              },
              {
                min: 0.01,
                max: 1000,
                color: '#25AD8D',
                label: 'Mere indkøbt'
              },
              {
                min: -1000,
                max: -0.01,
                color: '#ce0011',
                label: 'Mere solgt'
              }
            ],
            outOfRange: {
              color: '#999'
            }
          },
          series: {
            name: 'Nettoposition',
            type: 'line',
            areaStyle: {
              opacity: .25
            },
            data: this.oilNetPositionData.map(function (item: (number | string)[]) {
              return item[1];
            })
          }
        }

        this.showCharts = true
      })
  }
}
