<div class="flex flex-row justify-between items-end mb-4 gap-4">
  <h2 class="text-3xl text-secondary-dark">Prissikringsordrer</h2>
</div>

<app-card [noPadding]="true" class="relative">
  @if (isLoadingResults) {
  <div class="absolute top-0 left-0 bottom-0 right-0 bg-black/15 z-[1] flex items-center justify-center">
    <mat-spinner diameter="40"></mat-spinner>
  </div>
  }
  <table mat-table [dataSource]="orders" matSort multiTemplateDataRows (matSortChange)="handleSort($event)"
    [matSortActive]="sorting.active" [matSortDirection]="sorting.direction">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Ordrenummer</th>
      <td mat-cell *matCellDef="let element">{{element.id}}</td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Dato</th>
      <td mat-cell *matCellDef="let element">{{datePipe.transform(element.date, 'dd.MM.yyyy')}}</td>
    </ng-container>

    <ng-container matColumnDef="counterparty">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Modpart</th>
      <td mat-cell *matCellDef="let element">{{element.counterparty.displayName}}</td>
    </ng-container>

    <ng-container matColumnDef="confirmed">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Handelsbekræftelse</th>
      <td mat-cell *matCellDef="let element">
        @if (element.confirmed) {
        <mat-icon aria-hidden="false" aria-label="signed" fontIcon="check" class="tertiary-icon"></mat-icon>
        } @else {
        <mat-icon aria-hidden="false" aria-label="not signed" fontIcon="close" class="primary-icon"></mat-icon>
        }
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let element; columns: columns;" (click)="selectElement(element)"></tr>
  </table>
  <mat-paginator #paginator class="demo-paginator border-t" (page)="handlePageEvent($event)" [length]="length"
    [pageSize]="pageSize" [disabled]="paginationDisabled" [showFirstLastButtons]="showFirstLastButtons"
    [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize" [pageIndex]="pageIndex"
    aria-label="Select page">
  </mat-paginator>
</app-card>

<app-sidebar [show]="showSidebar" (emitClose)="closeDialog()" [title]="'Prissikringsordre ' + selectedElement?.id">
  <app-add-price-contract-dialog [order]="selectedElement" (cancel)="closeDialog()"></app-add-price-contract-dialog>
</app-sidebar>