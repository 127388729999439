import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({providedIn: 'root'})

export class PermissionsService {
  permissions: string[] = []

  constructor(
    private http: HttpClient
  ) {}

  async getPermissions() {
    return new Promise((resolve/*, reject*/) => {
      this.http.get('/api/v1/permissions').subscribe(response => {
        //console.debug('permissions', response)
        this.permissions = response as string[]
        resolve(true)
      })
    })
  }

  checkPermission(permission: string) {
    if (permission) {
      //console.debug(`checking permission ${permission}`, this.permissions.includes(permission))
      return this.permissions.includes(permission)
    }
    return true
  }
}