import { Component, OnInit } from '@angular/core';
import { CardComponent } from "../../../components/card.component";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule, Sort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { AddPriceContractDialogComponent } from './create.component';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Contract } from '../../../types';
import { CbmPipe } from '../../../cbm.pipe';
import { SidebarComponent } from "../../../components/sidebar.component";
import { Order } from "../agreements/agreements.component";
import { ContractsService, SummarizedContracts } from '../../../services/contracts.service';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [CardComponent, MatProgressSpinnerModule, MatTableModule, MatSortModule, MatButtonModule, MatIconModule, MatFormFieldModule, CbmPipe, SidebarComponent, AddPriceContractDialogComponent],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css',
  providers: [DatePipe]
})
export class DashboardComponent implements OnInit {
  constructor(public datePipe: DatePipe, private contractsService: ContractsService){}
  isLoadingResults = false
  showSidebar = false
  displayedColumns = ['number', 'date', 'customer', 'account', 'product', 'price', 'volume', 'profile', 'period', 'managedBy', 'signed']
  contracts: Contract[] = []

  filteredContracts() {
    return this.contracts.filter((e) => {
      return (e.profile === 'Landbrugsprofil')
    })
  }

  manualContractsB0() {
    return this.contracts.filter((e) => {
      return (e.profile === 'Manuel profil' && e.product.id === '1234')
    })
  }

  manualContractsB7() {
    return this.contracts.filter((e) => {
      return (e.profile === 'Manuel profil' && e.product.id === '3456')
    })
  }

  getVolumeSum() {
    let volume = 0
    for (const item of this.contracts) {
      volume += item.volume
    }
    return volume
  }

  summarizedContracts: SummarizedContracts[] = []
  summaryColumns = ['period', 'start', 'end', 'volumeB0', 'volumeB7', 'sum']

  getB0Volume() {
    let volume = 0
    for (const entry of this.summarizedContracts) {
      volume += entry.volumeB0
    }
    return volume
  }

  getB7Volume() {
    let volume = 0
    for (const entry of this.summarizedContracts) {
      volume += entry.volumeB7
    }
    return volume
  }

  getSummaryVolumeSum() {
    return this.getB0Volume() + this.getB7Volume()
  }

  /** Sorting */
  sorting: Sort = {
    active: 'date',
    direction: 'desc'
  }

  handleSort(e: Sort) {
    console.log(e);
    this.sorting = e;
  }

  data: {
    title: string;
    contracts: Contract[],
    order?: Order
    profile?: string
  } = {
    title: '',
    contracts: []
  }

  openDialog(contracts: Contract[], profile: string) {
    this.showSidebar = true
    this.data.title = 'Tilføj prissikringsordre'
    this.data.contracts = contracts
    this.data.profile = profile
  }

  ngOnInit(): void {
    this.contractsService.getSalesAgreements({'covered_eq': 'false'}).subscribe({
      next: (data) => {
        this.contracts = data.body as Contract[]
      },
      error: (err) => {
        console.debug(err)
      }
    })

    this.contractsService.getSummarizedContracts({}).subscribe({
      next: (data) => {
        this.summarizedContracts = data.body as SummarizedContracts[]
      },
      error: (err) => {
        console.debug(err)
      }
    })
  }
}
