export const environment = {
  production: true,
  msalConfig: {
    auth: {
      clientId: window['env']['azureClientId'],
      authority: window['env']['azureAuthEndpoint'],
    },
  },
  apiConfig: {
    scopes: [window['env']['scope']],
    uri: window['env']['azureRedirectUri'],
  },
};
