import { Component, Input } from "@angular/core";
import { RouterLink, RouterLinkActive } from "@angular/router";

@Component({
  selector: 'app-main-menu-link',
  template: `
    <a [routerLink]="link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: link === '/' ? true : false}"
    class="main-menu-link">{{ text }}</a>
  `,
  styles: `
    .main-menu-link {
      @apply inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium h-full;
    }

    .main-menu-link:not(.active) {
      @apply border-transparent text-secondary-light hover:border-gray-dark hover:text-secondary-dark;
    }

    .main-menu-link.active {
      @apply border-primary text-secondary-dark;
    }
  `,
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive
  ],
})

export class MainMenuLinkComponent {
  @Input({ required: true }) text!: string;
  @Input() link?: string;
}