<div class="flex flex-row justify-between items-end mb-4 gap-4">
  <h2 class="text-3xl text-secondary-dark">Handelsbekræftelser</h2>
</div>

<app-card [noPadding]="true" class="relative">
  @if (isLoadingResults) {
  <div class="absolute top-0 left-0 bottom-0 right-0 bg-black/15 z-[1] flex items-center justify-center">
    <mat-spinner diameter="40"></mat-spinner>
  </div>
  }
  <table mat-table [dataSource]="confirmations" matSort multiTemplateDataRows (matSortChange)="handleSort($event)"
    [matSortActive]="sorting.active" [matSortDirection]="sorting.direction">
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Dato</th>
      <td mat-cell *matCellDef="let element">{{datePipe.transform(element.date, 'dd.MM.yyyy')}}</td>
    </ng-container>

    <ng-container matColumnDef="tradeId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Trade ID</th>
      <td mat-cell *matCellDef="let element">{{element.tradeId}}</td>
    </ng-container>

    <ng-container matColumnDef="counterparty">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Modpart</th>
      <td mat-cell *matCellDef="let element">{{element.counterparty.displayName}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let element; columns: columns;" (click)="selectElement(element)"></tr>
  </table>
  <mat-paginator #paginator class="demo-paginator border-t" (page)="handlePageEvent($event)" [length]="length"
    [pageSize]="pageSize" [disabled]="paginationDisabled" [showFirstLastButtons]="showFirstLastButtons"
    [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize" [pageIndex]="pageIndex"
    aria-label="Select page"></mat-paginator>
</app-card>