import { DOCUMENT } from '@angular/common';
import { Directive, Inject, Input,/*OnDestroy,*/ OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appTeleport]',
  standalone: true
})
export class TeleportDirective implements OnInit /*, OnDestroy*/ {
  @Input() appTeleport!: string;

  private host!: Element

  constructor(
    private tpl: TemplateRef<HTMLElement>,
    private vcr: ViewContainerRef,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    const viewRef = this.vcr.createEmbeddedView(this.tpl);
    this.host = this.document.querySelector(this.appTeleport) as Element;
    this.host.innerHTML = "";
    viewRef.rootNodes.forEach(node => this.host.appendChild(node));
  }

  // ngOnDestroy() {
  //   this.host.innerHTML = "";  
  // }
}
