<div class="grid grid-cols-3 gap-4">
  <app-card>
    <h3 class="font-bold text-xl text-secondary-dark mb-4">
      Nettoposition olie
    </h3>
    @if (showCharts) {
    <div echarts [options]="oilNetPosition" [autoResize]="true" class="aspect-[4/3] relative"></div>
    }
  </app-card>
  <app-card>
    <h3 class="font-bold text-xl text-secondary-dark mb-4">
      Nettoposition el
    </h3>
    @if (showCharts) {
    <div echarts [options]="oilNetPosition" [autoResize]="true" class="aspect-[4/3] relative"></div>
    }
  </app-card>
  <app-card>
    <h3 class="font-bold text-xl text-secondary-dark mb-4">
      Nettoposition gas
    </h3>
    @if (showCharts) {
    <div echarts [options]="oilNetPosition" [autoResize]="true" class="aspect-[4/3] relative"></div>
    }
  </app-card>
</div>