import { Component } from '@angular/core';
import { TeleportDirective } from '../../teleport.directive';
import { RouterOutlet } from '@angular/router';
import { SidebarMenuItemComponent } from '../../components/sidebar-menu-item.component';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [TeleportDirective, RouterOutlet, SidebarMenuItemComponent],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.css'
})
export class SettingsComponent {
  children = [
    {
      type: 'header',
      text: 'Priser'
    },
    {
      text: 'Referencepriser',
      link: '/settings'
    },
    {
      text: 'Daglige priser',
      link: '/settings/test'
    },
    {
      type: 'header',
      text: 'Olie'
    },  
    {
      text: 'Produkter',
      link: '/settings/test'
    },
    {
      text: 'Profiler',
      link: '/settings/test'
    },
    {
      text: 'Periodeskabeloner',
      link: '/settings/test'
    },
    {
      type: 'header',
      text: 'El'
    },
    {
      text: 'Prisområder og indstillinger',
      link: '/settings/test'
    },
    {
      text: 'Produkttyper',
      link: '/settings/test'
    },
    {
      type: 'header',
      text: 'Gas'
    },
    {
      text: 'Lorem ipsum',
      link: '/settings/test'
    },
    {
      type: 'header',
      text: 'Administration'
    },
    {
      text: 'Kunder',
      link: '/settings/test'
    },
    {
      text: 'Modparter',
      link: '/settings/test'
    },
    {
      text: 'E-mailskabeloner',
      link: '/settings/test'
    },
    {
      text: 'Mailinglister',
      link: '/settings/test'
    },
  ]
}
