import { Component, OnInit } from '@angular/core';
import { CardComponent } from "../../../components/card.component";
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';
import { ContractsService } from '../../../services/contracts.service';

export interface Confirmation {
  date: string;
  tradeId: string;
  counterparty: {
    id: string;
    displayName: string;
  };
}

@Component({
  selector: 'app-confirmations',
  standalone: true,
  imports: [CardComponent, MatProgressSpinner, MatTableModule, MatSortModule, MatPaginatorModule],
  templateUrl: './confirmations.component.html',
  styleUrl: './confirmations.component.css',
  providers: [DatePipe]
})
export class ConfirmationsComponent implements OnInit {
  constructor(
    public datePipe: DatePipe,
    private contractsService: ContractsService
  ) {}

  isLoadingResults = false
  confirmations: Confirmation[] = []
  columns = ['date', 'tradeId', 'counterparty']

  selectElement(element: Confirmation) {
    console.log('select', element)
  }

  /** Sorting */
  sorting: Sort = {
    active: 'date',
    direction: 'desc'
  }

  handleSort(e: Sort) {
    console.log(e);
    this.sorting = e;
  }

  /** Pagination */
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  paginationDisabled = false;
  length = 2;
  pageSize = 15;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 15, 20];
  pageEvent: PageEvent | null = null;
  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    /* TODO: get results */
  }

  ngOnInit(): void {
    this.contractsService.getConfirmations({}).subscribe({
      next: (data) => {
        this.confirmations = data.body as Confirmation[]
      },
      error: (err) => {
        console.debug(err)
      }
    })
  }
}
