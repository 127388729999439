import { Component, /*inject,*/ Injectable, OnInit } from '@angular/core';
import { MatTableModule} from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { CardComponent } from "../../../components/card.component";
import { Sort, MatSortModule } from '@angular/material/sort';
import { ContractsService } from '../../../services/contracts.service';
import { DatePipe } from '@angular/common';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {SelectionModel} from '@angular/cdk/collections';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import type { Contract } from '../../../types';
import { Subject } from 'rxjs';
import 'moment/locale/da'
import { ActivatedRoute, Router } from '@angular/router';
import { CbmPipe } from '../../../cbm.pipe';
import { AddContractComponent } from './../oil/add-contract.component';
import { SidebarComponent } from "../../../components/sidebar.component";

@Injectable()
export class DanishPaginator implements MatPaginatorIntl {
  changes = new Subject<void>();
  firstPageLabel = 'Første side';
  lastPageLabel = 'Sidste side';
  nextPageLabel = 'Næste side';
  previousPageLabel = 'Forrige side';
  itemsPerPageLabel = 'Rækker pr. side';
  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return 'Side 1 af 1';
    }
    const amountPages = Math.ceil(length / pageSize);
    return `Side ${page + 1} af ${amountPages}`;
  }
}

@Component({
  selector: 'app-sales',
  standalone: true,
  imports: [
    MatTableModule,
    CardComponent,
    MatPaginator,
    MatSortModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIcon,
    MatProgressSpinnerModule,
    CbmPipe,
    AddContractComponent,
    SidebarComponent
],
  templateUrl: './oil.component.html',
  styleUrl: './oil.component.css',
  providers: [DatePipe, {provide: MatPaginatorIntl, useClass: DanishPaginator}]
})

export class OilComponent implements OnInit {
  showSidebar = false
  onlySigned = false
  displayedColumns: string[] = ['number', 'date', 'customer', 'account', 'product', 'volume', 'period', 'price', 'profile', 'customerManager', 'signed', 'covered'];
  contracts: Contract[] = []
  isLoadingResults = false
  selectedElement: Contract | null = null;

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  length = 50;
  pageSize = 15;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 15, 20];

  constructor(
    private contractsService: ContractsService,
    public datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getContracts({page: this.pageIndex, pageSize: this.pageSize})
    if (this.route.snapshot.queryParamMap.get('contract')) {
      const id = this.route.snapshot.queryParamMap.get('contract')
      this.contractsService.getContract(id as string).subscribe({
        next: (data: Contract) => {
          this.selectedElement = data
          this.openDialog()
        },
        error: (err) => {
          console.debug(err)
        }
      })
    }
  }

  selectElement(element: Contract) {
    this.router.navigate([], {
      queryParams: {
        contract: element.id
      },
      queryParamsHandling: 'merge',
    });
    this.selectedElement = element
    this.openDialog()
  }

  getContracts(params: Record<string, string | number>) {
    this.isLoadingResults = true
    this.contractsService.getContracts(params).subscribe({
      next: (data) => {
        this.isLoadingResults = false
        this.length = data.total
        this.contracts = data.results
        // this.length = parseInt(data.headers.get('X-Total-Count') as string)
        // this.contracts = data.body as Contract[]
      },
      error: (err) => {
        this.isLoadingResults = false
        console.debug(err)
      }
    })
  }

  // readonly dialog = inject(MatDialog)
  // openDialog() {
  //   const dialogRef = this.dialog.open(AddContractComponent, {
  //     data: this.selectedElement,
  //     width: 'calc(100% - 30px)',
  //     maxWidth: '1200px',
  //     height: '65vh'
  //   });
  //   dialogRef.afterClosed().subscribe(() => {
  //     this.selectedElement = null
  //     this.router.navigate([], {
  //       queryParams: {
  //         contract: null
  //       },
  //       queryParamsHandling: 'merge',
  //     })
  //   })
  //   dialogRef.componentInstance.onAddContract.subscribe((data) => {
  //     console.log('hello from parent', data)
  //     this.getContracts({page: this.pageIndex, pageSize: this.pageSize})
  //   })
  // }
  openDialog() {
    this.showSidebar = true
  }

  closeDialog() {
    this.showSidebar = false
    this.selectedElement = null
    this.router.navigate([], {
      queryParams: {
        contract: null
      },
      queryParamsHandling: 'merge',
    })
  }

  saveContract(data: Contract) {
    console.log('hello from parent', data)
    this.getContracts({page: this.pageIndex, pageSize: this.pageSize})
  }

  /** Row selection */
  selection = new SelectionModel<Contract>(true, []);

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.contracts ? this.contracts.length : 0;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.contracts);
  }
  
  checkboxLabel(row?: Contract): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id}`;
  }

  /** Filtering */
  filter = ''
  volumeFrom = null
  volumeTo = null
  priceFrom = null
  priceTo = null
  filterTimeout!: ReturnType<typeof setTimeout>
  handleFilter(e: Event) {
    clearTimeout(this.filterTimeout)
    this.filterTimeout = setTimeout(() => {
      console.log(e)
    }, 500);
  }

  /** Pagination */
  pageEvent: PageEvent | null = null;
  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getContracts({page: this.pageIndex, pageSize: this.pageSize})
  }

  /** Sorting */
  sorting: Sort = {
    active: 'date',
    direction: 'desc'
  }

  handleSort(e: Sort) {
    console.log(e);
    this.sorting = e;
  }

  /** Clicking a row */
  handleClick(row: Contract) {
    console.log(row)
  }
}

export interface Group {
  name: string;
  products: GroupOption[]
}

export interface GroupOption {
  displayName: string;
  id: string;
  unit: string;
  prefix?: string;
}

export interface PeriodTemplate {
  displayName: string;
  canSetFromDate: boolean;
  canSetToDate: boolean;
}
