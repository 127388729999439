import { Component } from '@angular/core';

@Component({
  selector: 'app-credit-lines',
  standalone: true,
  imports: [],
  templateUrl: './credit-lines.component.html',
  styleUrl: './credit-lines.component.css'
})
export class TradeLinesComponent {

}
