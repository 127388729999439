import { Component, Input } from "@angular/core";
import { RouterLink, RouterLinkActive } from "@angular/router";

@Component({
  selector: 'app-sidebar-menu-item',
  template: `
    @if(item.type && item.type === 'header') {
    <h4 class="text-xs font-bold uppercase px-4 py-2 border-b text-secondary-lighter bg-gray-100">{{item.text}}
    </h4>
    } @else {
    <button class="px-4 py-2 border-b w-full hover:bg-gray transition-colors" [routerLink]="item.link" [routerLinkActive]="['bg-primary','text-white','hover:!bg-primary']"
      [routerLinkActiveOptions]="{exact: true}">{{item.text}}</button>
    }
  `,
  styles: ``,
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive
  ],
})

export class SidebarMenuItemComponent {
  @Input({ required: true }) item!: { type?: string | undefined; link?: string, text: string};
}