import { CanActivateFn } from '@angular/router';
import { PermissionsService } from '../services/permissions.service';
import { inject } from '@angular/core';

export const permissionGuard: CanActivateFn = async (route/*, state*/) => {
  const perms = inject(PermissionsService)
  if (!perms.permissions.length) {
    await perms.getPermissions()
  }
  return perms.checkPermission(route.data['permission'])
};
